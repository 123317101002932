<template>
  <section>
    <b-form-group :label="useLabel" :label-cols-sm="cols">
      <div class="d-flex flex-row">
        <div>
          <b-form-input
            :id="startDate == null ? 'start-date-input' : 'end-date-input'"
            v-model="date"
            :disabled="isDisabled || disableDate"
            @change="inputDateChanged"
            type="text"
            placeholder="YYYY-MM-DD"
            :state="IsValidDate == true ? null : false"
            autocomplete="off"
            class="w-150px"
            :aria-label="$t('NARR.PICK_DATE_TIMES.INPUT_TO_ENTER_START_DATE')"
          ></b-form-input>
        </div>
        <div>
          <b-form-datepicker
            v-model="datepicker"
            :disabled="disableDate"
            initial-date="date"
            label-help=""
            class="no-border"
            start-weekday="1"
            button-only
            hide-header
            @context="DatePickerChanged"
            ><template #button-content>
              <i class="fal fa-calendar-days"></i></template
          ></b-form-datepicker>
        </div>
        <div v-if="useClock">
          <b-form-input
            :id="startDate == null ? 'start-time-input' : 'end-time-input'"
            type="text"
            v-model="time"
            @change="inputTimeChanged"
            :disabled="isDisabled"
            :state="IsValidTime == true ? null : false"
            placeholder="HH:MM"
            class="w-100px"
            :aria-label="$t('NARR.PICK_DATE_TIMES.INPUT_TO_ENTER_START_TIME')"
          ></b-form-input>
        </div>
        <div v-if="useClock">
          <b-form-timepicker
            v-model="timepicker"
            button-only
            ref="TimePicker"
            :hour12="false"
            :minutes-step="15"
            hide-header
            @context="TimePickerChanged"
            no-flip
            :show-seconds="false"
            aria-controls="example-input"
          >
            <template #button-content> <i class="fal fa-clock"></i></template>
          </b-form-timepicker>
        </div>
      </div>

      <div class="error-text" v-if="!IsValidFromTo">
        {{ $t("ERROR.START_AFTER_END") }}
      </div>
    </b-form-group>
  </section>
</template>

<script>
import { DateTime } from "luxon";
export default {
  props: {
    value: {
      type: [Object, String],
      default: null,
    },
    startDate: {
      type: [Object, String],
      default: null,
    },
    useLabel: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    useClock: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    disableDate: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
  },
  data() {
    return {
      date: "",
      time: "",
      datetime: "",
      datepicker: "",
      timepicker: "",
    };
  },
  methods: {
    inputDateChanged() {
      this.EmitDateTime();
    },
    DatePickerChanged(context) {
      if (context.activeYMD != this.date) {
        this.date = context.activeYMD;
        this.EmitDateTime();
      }
    },
    inputTimeChanged() {
      this.EmitDateTime();
    },
    TimePickerChanged(context) {
      let result = context.hours + ":";
      if (context.minutes < 10) {
        result += "0" + context.minutes;
      } else {
        result += context.minutes;
      }
      if (context.hours < 10) {
        result = "0" + result;
      }
      if (result != this.time) {
        this.time = result;
        this.EmitDateTime();
      }
    },
    openDatePicker() {
      const ref = this.$refs.DatePicker;
      if (ref) {
        if (typeof ref.show === "function") {
          ref.show();
        }
        ref.isOpen = true;
      }
    },
    CheckValidDate(date) {
      return (
        date &&
        Object.prototype.toString.call(date) === "[object Date]" &&
        !isNaN(date)
      );
    },
    IsStartBeforeThis(val) {
      if(this.datetime == "0001-01-01T00:00:00" && val == "0001-01-01T00:00:00")
      {
        return false;
      }
      if (this.datetime == undefined) {
        return false;
      }
      if (this.datetime == null) {
        return false;
      }
      let datetime = DateTime.fromISO(this.datetime, { zone: "utc" });
      let datetime2 = DateTime.fromISO(val, { zone: "utc" });
      return datetime2 >= datetime;
    },
    EmitDateTime() {
      if (this.useClock) {
        const formattedDateTime = DateTime.fromFormat(
          `${this.date} ${this.time}`,
          "yyyy-MM-dd HH:mm",
          { zone: "local" }
        )
          .toUTC()
          .toISO();
        this.$emit("input", formattedDateTime);
        this.$emit("change");
      } else {
        const formattedDateTime = DateTime.fromFormat(
          this.date + " 00:00",
          "yyyy-MM-dd HH:mm",
          { zone: "local" }
        )
          .toUTC()
          .toISO();
        this.$emit("input", formattedDateTime);
        this.$emit("change");
      }
    },
    initDates() {
      const minDate = "0001-01-01T00:00:00";
      const maxDate = "9999-12-31T23:59:59";
      if (
        !this.datetime ||
        this.datetime === minDate ||
        this.datetime === maxDate
      ) {
        let now = new DateTime.local();
        if (this.startDate != null) {
          now = now.plus({ minutes: 60 });
        }
        this.date = now.toFormat("yyyy-MM-dd");
        this.time = now.toFormat("HH:mm");
        this.EmitDateTime();
      } else {
        let datetime = DateTime.fromISO(this.datetime, { zone: "utc" });
        const localStartDateTime = datetime.setZone("local");
        this.date = localStartDateTime.toFormat("yyyy-MM-dd");
        if (this.useClock) {
          this.time = localStartDateTime.toFormat("HH:mm");
        }
      }
      this.datepicker = this.date;
      this.timepicker = this.time;
    },
  },
  computed: {
    cols() {
      return this.useLabel != "" ? 2 : 0;
    },
    IsValidFromTo() {
      if (this.startDate != null && this.startDate != "") {
        return !this.IsStartBeforeThis(this.startDate);
      }
      return true;
    },
    IsValidDate() {
      if (!this.date) {
        return false;
      }
      let datetime = new DateTime.fromFormat(
        `${this.date} 18:00`,
        "yyyy-MM-dd HH:mm"
      ).toUTC();
      return datetime.isValid && this.IsValidFromTo;
    },
    IsValidTime() {
      if (this.useClock) {
        let datetime = new DateTime.fromFormat(
          `2024-08-14 ${this.time}`,
          "yyyy-MM-dd HH:mm"
        ).toUTC();
        return datetime.isValid && this.IsValidFromTo;
      } else {
        return true;
      }
    },
    IsValid() {
      return this.IsValidDate && this.IsValidTime;
    },
  },
  watch: {
    startDate(val) {
      const minDate = "0001-01-01T00:00:00";
      const maxDate = "9999-12-31T23:59:59";
      if (val != null && val !== "" && val !== minDate && val !== maxDate) {
        if (this.IsStartBeforeThis(val)) {
          if (this.CheckValidDate(val)) {
            let datetime = val;
            datetime = datetime.plus({ minutes: 60 });
            const localStartDateTime = datetime.setZone("local");
            this.date = localStartDateTime.toFormat("yyyy-MM-dd");
            this.time = localStartDateTime.toFormat("HH:mm");
            this.EmitDateTime();
          } else {
            let datetime = DateTime.fromISO(this.startDate, { zone: "utc" });
            datetime = datetime.plus({ minutes: 60 });
            const localStartDateTime = datetime.setZone("local");
            this.date = localStartDateTime.toFormat("yyyy-MM-dd");
            this.time = localStartDateTime.toFormat("HH:mm");
            this.EmitDateTime();
          }
        }
      }
    },
    IsValid() {
      this.$emit("valid", this.IsValid);
    },
    value(val) {
      this.datetime = val;
      this.initDates();
    },
  },
  mounted() {
    this.datetime = this.value;
    this.initDates();
  },
};
</script>