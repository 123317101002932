<template>
  <section class="out-of-office-message" v-if="showOutOfOffice">
    {{ $t('NARR.OUT_OF_OFFICE.ACCOUNT_ACTIVE')}} <b-button size="sm" class="no-border ml-2" @click="Deactivate">{{ $t('DEACTIVATE') }}</b-button>
  </section>
</template>
<script>
import { DateTime } from "luxon";
export default {
  data() {
    return {
    };
  },
  methods: {
    async Deactivate(){
      try {
        let dto = {
            account_id: this.user.information.id,
            out_of_office_enabled: false
        };
        await this.$http.post(`${this.user.hostname}/user/edit/out-of-office`,dto);
        this.$noty.info(this.$t('DEACTIVATED'));
        await this.$store.dispatch("user/fetchUser");
      // eslint-disable-next-line no-empty
      } catch {}
    }
  },
  computed: {    
    showOutOfOffice(){
      let start = DateTime.fromISO(this.user.information.out_of_office_start_date, { zone: "utc" });
      let end = DateTime.fromISO(this.user.information.out_of_office_end_date, { zone: "utc" });
      let now = new DateTime.local().toUTC();
      if(this.user.information.out_of_office_date != undefined)
      {
        if(this.user.information.out_of_office_enabled && !this.user.information.out_of_office_date)
        {
          return true;
        }
        if(this.user.information.out_of_office_enabled && this.user.information.out_of_office_date && start < now && now < end){
          return true;
        }
      }else{
        return this.user.information.out_of_office_enabled;
      }
      return false;
    }
  },
  mounted: function() {   
  },
};
</script>
<style></style>